const TRANSLATIONS = {
  common: {
    "workspaces-name": "Nazwa przestrzeni roboczych",
    error: "błąd",
    success: "sukces",
    user: "Użytkownik",
    selection: "Wybór modelu",
    saving: "Zapisywanie...",
    save: "Zapisz zmiany",
    previous: "Poprzednia strona",
    next: "Następna strona",
  },

  // Popup Confirmation
  deleteWorkspaceConfirmation:
    "Czy na pewno chcesz usunąć {{name}}?\nPo wykonaniu tej czynności będzie to niedostępne w tej instancji.\n\nTa akcja jest nieodwracalna.",
  deleteConfirmation:
    "Czy na pewno chcesz usunąć ${user.username}?\nPo wykonaniu tej czynności użytkownik zostanie wylogowany i nie będzie miał dostępu do tej instancji.\n\nTa akcja jest nieodwracalna.",
  suspendConfirmation:
    "Czy na pewno chcesz zawiesić {{username}}?\nPo wykonaniu tej czynności użytkownik zostanie wylogowany i nie będzie mógł się zalogować, dopóki nie zostanie odwieszony przez administratora.",
  flushVectorCachesWorkspaceConfirmation:
    "Czy na pewno chcesz wyczyścić pamięć podręczną wektorów dla tego obszaru roboczego?",

  // Setting Sidebar menu items.
  settings: {
    title: "Ustawienia instancji",
    system: "Ustawienia ogólne",
    invites: "Zaproszenia",
    users: "Użytkownicy",
    workspaces: "Przestrzenie robocze",
    "workspace-chats": "Czaty w przestrzeniach roboczych",
    customization: "Dostosowanie",
    "api-keys": "API dla deweloperów",
    llm: "LLM",
    transcription: "Transkrypcja",
    embedder: "Osadzanie",
    "text-splitting": "Dzielenie i segmentacja tekstu",
    "vector-database": "Baza danych wektorów",
    embeds: "Osadzanie czatów",
    "embed-chats": "Historia osadzonych czatów",
    security: "Bezpieczeństwo",
    "event-logs": "Logi zdarzeń",
    privacy: "Prywatność i dane",
    "ai-providers": "Dostawcy AI",
    "agent-skills": "Umiejętności agenta",
    admin: "Administrator",
    tools: "Narzędzia",
    audio: "Preferencje audio",
    "link-settings": "Ustawienia",
    "default-settings": "Domyślne ustawienia",
    "browser-extension": "Rozszerzenie przeglądarki",
  },

  // Qura buttons,
  qura: {
    "copy-to-cora": "Sprawdź źródło w Qura",
    "qura-status": "Przycisk Qura jest ",
    "copy-option": "Kopiuj opcję",
    "option-quest": "Pytanie",
    "option-resp": "Odpowiedź",
    "role-description":
      "Dodaj przycisk Qura, aby uzyskać odpowiedzi w Qura.law",
  },

  thread_name_error:
    "Nazwa wątku musi mieć co najmniej 3 znaki i zawierać tylko litery, cyfry, spacje lub myślniki.",

  // Page Definitions
  login: {
    "multi-user": {
      welcome: "Witamy w",
      "placeholder-username": "Nazwa użytkownika",
      "placeholder-password": "Hasło",
      login: "Zaloguj się",
      validating: "Weryfikacja...",
      "forgot-pass": "Zapomniałeś hasła",
      reset: "Resetuj",
    },
    "sign-in": {
      start: "Zaloguj się na swoje konto",
      end: "konto.",
    },
    button: "logowanie",
    password: {
      forgot: "ZAPOMNIAŁEŚ HASŁA? ",
      contact: "Skontaktuj się z administratorem systemu.",
    },
    publicMode: "Tryb publiczny",
  },

  "new-workspace": {
    title: "Nowa przestrzeń robocza",
    placeholder: "Moja przestrzeń robocza",
    "legal-areas": "Obszary prawne",
    create: {
      title: "Utwórz nową przestrzeń roboczą",
      description:
        "Po utworzeniu tej przestrzeni roboczej tylko administratorzy będą mogli ją zobaczyć. Możesz dodać użytkowników po jej utworzeniu.",
      error: "Błąd: ",
      cancel: "Anuluj",
      "create-workspace": "Utwórz przestrzeń roboczą",
    },
  },

  "workspace-chats": {
    welcome: "Witamy w nowej przestrzeni roboczej.",
    "desc-start": "Aby rozpocząć, ",
    "desc-mid": "prześlij dokument",
    "desc-or": "lub",
    start: "Aby rozpocząć",
    "desc-end": "wyślij czat.",
    prompt: {
      send: "Wyślij",
      "send-message": "Wyślij wiadomość",
      placeholder: "Zapytaj o informacje prawne",
      slash: "Zobacz wszystkie dostępne polecenia dla czatów.",
      "change-size": "Zmień rozmiar tekstu",
      reset: "Zresetuj swój czat",
      clear: "Wyczyść historię czatu i rozpocznij nowy czat",
      "new-preset": "Dodaj nowy preset",
      command: "Polecenie",
      description: "Opis",
      save: "zapisz",
      small: "Mały",
      normal: "Normalny",
      large: "Duży",
      attach: "Załącz plik do tego czatu",
      upgrade: "Zaktualizuj swoje zapytanie",
      upgrading: "Aktualizacja zapytania",
      "original-prompt": "Oryginalne zapytanie:",
      "upgraded-prompt": "Zaktualizowane zapytanie:",
      "edit-prompt": "Możesz edytować nowe zapytanie przed jego wysłaniem",
    },
  },

  header: {
    account: "Konto",
    login: "Logowanie",
    "sign-out": "Wyloguj się",
  },

  workspace: {
    title: "Przestrzenie robocze instancji",
    description:
      "To są wszystkie przestrzenie robocze dostępne w tej instancji. Usunięcie przestrzeni roboczej usunie wszystkie jej czaty i ustawienia.",
    "new-workspace": "Nowa przestrzeń robocza",
    name: "Nazwa",
    link: "Link",
    users: "Użytkownicy",
    type: "Typ",
    "created-on": "Utworzono dnia",
    save: "Zapisz zmiany",
    cancel: "Anuluj",
    deleted: {
      title: "Przestrzeń robocza nie znaleziona!",
      description:
        "Wygląda na to, że przestrzeń robocza o tej nazwie nie jest dostępna.",
      homepage: "Powrót do strony głównej",
    },
    "no-workspace": {
      title: "Brak dostępnych przestrzeni roboczych",
      description: "Nie masz jeszcze dostępu do żadnych przestrzeni roboczych.",
      "contact-admin":
        "Skontaktuj się z administratorem, aby poprosić o dostęp.",
      "learn-more": "Dowiedz się więcej o przestrzeniach roboczych",
    },
  },

  // Workspace Settings menu items
  "workspaces-settings": {
    general: "Ogólne Ustawienia",
    chat: "Ustawienia Czatów",
    vector: "Baza Danych Wektorów",
    members: "Członkowie",
    agent: "Konfiguracja Agenta",
    "general-settings": {
      "workspace-name": "Nazwa Przestrzeni Roboczej",
      "desc-name":
        "To zmieni tylko nazwę wyświetlaną Twojej przestrzeni roboczej.",
      "assistant-profile": "Obraz Profilu Asystenta",
      "assistant-image":
        "Dostosuj obraz profilu asystenta dla tej przestrzeni roboczej.",
      "workspace-image": "Obraz Przestrzeni Roboczej",
      "remove-image": "Usuń Obraz Przestrzeni Roboczej",
      delete: "Usuń Przestrzeń Roboczą",
      deleting: "Usuwanie Przestrzeni Roboczej...",
      update: "Aktualizuj Przestrzeń Roboczą",
      updating: "Aktualizowanie Przestrzeni Roboczej...",
    },
    "chat-settings": {
      type: "Typ Czatu",
      private: "Prywatny",
      standard: "Standardowy",
      "private-desc-start": "ręcznie przyzna dostęp",
      "private-desc-mid": "tylko",
      "private-desc-end": "konkretnym użytkownikom.",
      "standard-desc-start": "automatycznie przyzna dostęp",
      "standard-desc-mid": "wszystkim",
      "standard-desc-end": "nowym użytkownikom.",
    },
    users: {
      manage: "Zarządzaj Użytkownikami",
      "workspace-member": "Brak członków przestrzeni roboczej",
      username: "Nazwa Użytkownika",
      role: "Rola",
      date: "Data Dodania",
      users: "Użytkownicy",
      search: "Wyszukaj użytkownika",
      "no-user": "Nie znaleziono użytkowników",
      select: "Zaznacz Wszystkich",
      unselect: "Odznacz Wszystkich",
      save: "Zapisz",
    },
    "linked-workspaces": {
      title: "Powiązane Przestrzenie Robocze",
      "linked-workspace": "Brak powiązanych przestrzeni roboczych",
      manage: "Zarządzaj Przestrzeniami Roboczymi",
      name: "Nazwa",
      slug: "Slug",
      date: "Data Dodania",
      workspaces: "Przestrzenie Robocze",
      search: "Wyszukaj przestrzeń roboczą",
      "no-workspace": "Nie znaleziono przestrzeni roboczych",
      select: "Zaznacz Wszystkich",
      unselect: "Odznacz Wszystkich",
      save: "Zapisz",
    },
  },

  // General Appearance
  general: {
    vector: {
      title: "Liczba Wektorów",
      description: "Całkowita liczba wektorów w Twojej bazie danych wektorów.",
      vectors: "Liczba wektorów",
    },
    names: {
      description:
        "To zmieni tylko nazwę wyświetlaną Twojej przestrzeni roboczej.",
    },
    message: {
      title: "Sugestie Wiadomości Czatowych",
      description:
        "Dostosuj wiadomości sugerowane użytkownikom Twojej przestrzeni roboczej.",
      add: "Dodaj nową wiadomość",
      save: "Zapisz Wiadomości",
      heading: "Wyjaśnij mi",
      body: "korzyści z platformy",
      message: "Wiadomość",
      "new-heading": "Nagłówek",
    },
    pfp: {
      title: "Obraz Profilu Asystenta",
      description:
        "Dostosuj obraz profilu asystenta dla tej przestrzeni roboczej.",
      image: "Obraz Przestrzeni Roboczej",
      remove: "Usuń Obraz Przestrzeni Roboczej",
    },
    delete: {
      delete: "Usuń Przestrzeń Roboczą",
      deleting: "Usuwanie Przestrzeni Roboczej...",
      "confirm-start": "Zaraz usuniesz całą swoją",
      "confirm-end":
        "przestrzeń roboczą. To usunie wszystkie osadzenia wektorów z Twojej bazy danych wektorów.\n\nOryginalne pliki źródłowe pozostaną nienaruszone. Ta operacja jest nieodwracalna.",
    },
  },

  // Chat Settings
  chat: {
    llm: {
      title: "Dostawca LLM dla Przestrzeni Roboczej",
      description:
        "Specyficzny dostawca i model LLM, który będzie używany w tej przestrzeni roboczej. Domyślnie używa systemowego dostawcy LLM i ustawień.",
      search: "Wyszukaj wszystkich dostawców LLM",
    },
    model: {
      title: "Model Czatowy dla Przestrzeni Roboczej",
      description:
        "Specyficzny model czatu, który będzie używany w tej przestrzeni roboczej. Jeśli puste, zostanie użyty preferowany model systemowy LLM.",
      wait: "-- oczekiwanie na modele --",
    },
    mode: {
      title: "Tryb Czatu",
      chat: {
        title: "Czat",
        "desc-start": "będzie udzielał odpowiedzi z ogólną wiedzą LLM",
        and: "oraz",
        "desc-end": "kontekstem dokumentów, które zostaną znalezione.",
      },
      query: {
        title: "Zapytanie",
        "desc-start": "będzie udzielał odpowiedzi",
        only: "tylko",
        "desc-end": "jeśli zostanie znaleziony kontekst dokumentu.",
      },
    },
    history: {
      title: "Historia Czatu",
      "desc-start":
        "Liczba poprzednich czatów, które będą uwzględnione w pamięci krótkoterminowej odpowiedzi.",
      recommend: "Zalecane: 20. ",
      "desc-end":
        "Więcej niż 45 może prowadzić do niepowodzeń czatu w zależności od rozmiaru wiadomości.",
    },
    prompt: {
      title: "Polecenie",
      description:
        "Polecenie, które będzie używane w tej przestrzeni roboczej. Zdefiniuj kontekst i instrukcje dla AI, aby wygenerować odpowiedź. Powinieneś starannie opracować polecenie, aby AI mogło wygenerować odpowiednią i dokładną odpowiedź.",
    },
    refusal: {
      title: "Odpowiedź odmowna trybu zapytania",
      "desc-start": "W trybie",
      query: "zapytania",
      "desc-end":
        "możesz chcieć zwrócić niestandardową odpowiedź odmowną, gdy nie zostanie znaleziony żaden kontekst.",
    },
    temperature: {
      title: "Temperatura LLM",
      "desc-start":
        "To ustawienie kontroluje, jak „kreatywne” będą odpowiedzi LLM.",
      "desc-end":
        "Im wyższa liczba, tym bardziej kreatywne. W niektórych modelach może to prowadzić do niespójnych odpowiedzi, gdy jest ustawione zbyt wysoko.",
      hint: "Większość LLM ma różne akceptowalne zakresy wartości. Skonsultuj się z dostawcą LLM, aby uzyskać te informacje.",
    },
    max_tokens: {
      title: "Maksymalna liczba tokenów logowania na użytkownika",
      desc: "Ustaw maksymalną liczbę aktywnych tokenów uwierzytelniania, które każdy użytkownik może mieć jednocześnie. Po przekroczeniu limitu starsze tokeny zostaną automatycznie usunięte.",
      label: "Maksymalna liczba tokenów",
      help: "Wartość musi być większa niż 0",
    },
  },

  // Vector Database
  "vector-workspace": {
    identifier: "Identyfikator bazy danych wektorów",
    snippets: {
      title: "Maksymalna liczba fragmentów kontekstu",
      description:
        "To ustawienie kontroluje maksymalną liczbę fragmentów kontekstu, które zostaną przesłane do LLM na czat lub zapytanie.",
      recommend: "Zalecane: 4",
    },
    doc: {
      title: "Próg podobieństwa dokumentów",
      description:
        "Minimalny wynik podobieństwa wymagany, aby źródło było uznane za powiązane z czatem. Im wyższa liczba, tym bardziej podobne musi być źródło do czatu.",
      zero: "Brak ograniczeń",
      low: "Niski (wynik podobieństwa ≥ 0,25)",
      medium: "Średni (wynik podobieństwa ≥ 0,50)",
      high: "Wysoki (wynik podobieństwa ≥ 0,75)",
    },
    reset: {
      reset: "Zresetuj Bazę Danych Wektorów",
      resetting: "Czyszczenie wektorów...",
      confirm:
        "Zaraz zresetujesz bazę danych wektorów tej przestrzeni roboczej. Spowoduje to usunięcie wszystkich obecnie osadzonych wektorów.\n\nOryginalne pliki źródłowe pozostaną nienaruszone. Ta operacja jest nieodwracalna.",
      error: "Nie udało się zresetować bazy danych wektorów!",
      success: "Baza danych wektorów została zresetowana!",
    },
  },

  // Agent Configuration
  agent: {
    "performance-warning":
      "Wydajność LLM, które nie obsługują jawnie wywołań narzędzi, jest wysoce zależna od możliwości i dokładności modelu. Niektóre funkcje mogą być ograniczone lub nie działać.",
    provider: {
      title: "Dostawca LLM Agenta Przestrzeni Roboczej",
      description:
        "Specyficzny dostawca i model LLM, który będzie używany przez agenta przestrzeni roboczej.",
    },
    mode: {
      chat: {
        title: "Model Czatowy Agenta Przestrzeni Roboczej",
        description:
          "Specyficzny model czatu, który będzie używany przez agenta przestrzeni roboczej.",
      },
      title: "Model Agenta Przestrzeni Roboczej",
      description:
        "Specyficzny model LLM, który będzie używany przez agenta przestrzeni roboczej.",
      wait: "-- oczekiwanie na modele --",
    },

    skill: {
      title: "Domyślne umiejętności agenta",
      description:
        "Popraw naturalne zdolności domyślnego agenta za pomocą tych wstępnie zbudowanych umiejętności. Te ustawienia mają zastosowanie we wszystkich przestrzeniach roboczych.",
      rag: {
        title: "RAG i pamięć długoterminowa",
        description:
          "Pozwól agentowi korzystać z lokalnych dokumentów, aby odpowiedzieć na zapytanie, lub poproś agenta, aby „zapamiętał” fragmenty treści do późniejszego odzyskania z pamięci długoterminowej.",
      },
      configure: {
        title: "Konfiguruj Umiejętności Agenta",
        description:
          "Dostosuj i ulepsz możliwości domyślnego agenta, włączając lub wyłączając określone umiejętności. Te ustawienia będą stosowane we wszystkich przestrzeniach roboczych.",
      },
      view: {
        title: "Przeglądaj i podsumowuj dokumenty",
        description:
          "Pozwól agentowi wyświetlać i podsumowywać treści plików przestrzeni roboczej obecnie osadzonych.",
      },
      scrape: {
        title: "Zbieraj dane z witryn",
        description:
          "Pozwól agentowi odwiedzać i zbierać treści z witryn internetowych.",
      },
      generate: {
        title: "Generuj wykresy",
        description:
          "Włącz możliwość generowania różnych typów wykresów na podstawie dostarczonych danych lub danych w czacie.",
      },
      save: {
        title: "Generuj i zapisuj pliki w przeglądarce",
        description:
          "Pozwól agentowi generować i zapisywać pliki, które mogą być pobrane w Twojej przeglądarce.",
      },
      web: {
        title: "Przeszukiwanie internetu na żywo",
        "desc-start":
          "Pozwól swojemu agentowi przeszukiwać internet, aby odpowiedzieć na Twoje pytania, łącząc się z dostawcą wyszukiwarki internetowej (SERP).",
        "desc-end":
          "Przeszukiwanie internetu podczas sesji agenta nie będzie działać, dopóki to ustawienie nie zostanie skonfigurowane.",
      },
    },
  },

  // Workspace Chats
  recorded: {
    title: "Workspace Chats",
    description:
      "These are all the recorded chats and messages that have been sent by users ordered by their creation date.",
    export: "Export",
    table: {
      id: "Id",
      by: "Sent By",
      workspace: "Workspace",
      prompt: "Prompt",
      response: "Response",
      at: "Sent At",
    },
    "clear-chats": "Clear Chats",
    "confirm-clear-chats":
      "Are you sure you want to clear all chats?\n\nThis action is irreversible.",
    "fine-tune-modal": "Order Fine-Tune Model",
    "confirm-delete.chat":
      "Are you sure you want to delete this chat?\n\nThis action is irreversible.",
    next: "Next Page",
    previous: "Previous Page",
  },

  // Appearance
  appearance: {
    title: "Appearance",
    description: "Customize the appearance settings of your platform.",
    logo: {
      title: "Customize logo",
      description: "Upload your custom logo for light mode.",
      add: "Add a custom logo",
      recommended: "Recommended size: 800 x 200",
      remove: "Remove",
    },
    logoDark: {
      title: "Customize dark mode logo",
      description: "Upload your custom logo for dark mode.",
    },
    message: {
      title: "Customize Messages",
      description: "Customize the automatic messages displayed to your users.",
      new: "New",
      system: "system",
      user: "user",
      message: "message",
      assistant: "Chat Assistant",
      "double-click": "Double click to edit...",
      save: "Save Messages",
    },
    icons: {
      title: "Custom Footer Icons",
      description:
        "Customize the footer icons displayed on the bottom of the sidebar.",
      icon: "Icon",
      link: "Link",
    },
    display: {
      title: "Display Language",
      description: "Select the preferred language.",
    },
    color: {
      title: "Custom colors",
      "desc-start": "Customize the",
      "desc-mid": "*background-color, *primary-color",
      "desc-and": "and",
      "desc-end": "*text-color",
      red: "Red",
      gray: "Gray",
      foynet: "Foynet",
      brown: "Brown",
      green: "Green",
      yellow: "Yellow",
      cyan: "Cyan",
      magenta: "Magenta",
      orange: "TenderFlow",
      purple: "Purple",
      navy: "Navy",
      black: "Black",
    },
    login: {
      title: "Custom Login Text",
      description: "Customize the paragraph text displayed on the login page.",
      placeholder: "Please contact the admin of the system",
      website: {
        title: "Customize the Application Website",
        description: "Customize the URL of your application's website.",
        toggle: "Display Website Link",
      },
      validation: {
        invalidURL: "Please enter a valid URL.",
      },
    },
    siteSettings: {
      title: "Custom Site Settings",
      description:
        "Change the content of the browser tab for customization and branding.",
      tabTitle: "Tab Title",
      tabDescription:
        "Set a custom tab title when the app is open in a browser.",
      tabIcon: "Tab Favicon",
      fabIconUrl: "Define a url to an image to use for your favicon",
      placeholder: "url to your image",
    },
    appName: {
      title: "Custom App Name",
      description: "Set a custom app name that is displayed on the login page.",
    },
    customTab: {
      title: "Customize Tab Name",
      tab1: "Tab 1",
      tab2: "Tab 2",
    },
  },

  // API Keys
  api: {
    title: "API Keys",
    description:
      "API keys allow the holder to programmatically access and manage this instance.",
    link: "Read the API documentation",
    generate: "Generate New API Key",
    table: {
      key: "API Key",
      by: "Created By",
      created: "Created",
    },
    new: {
      title: "Create new API key",
      description:
        "Once created the API key can be used to programmatically access and configure this instance.",
      doc: "Read the API documentation",
      cancel: "Cancel",
      "create-api": "Create API key",
    },
  },

  llm: {
    title: "LLM Preference",
    description:
      "These are the credentials and settings for your preferred LLM chat & embedding provider. Its important these keys are current and correct or else the system will not function properly.",
    provider: "LLM Provider",
    "none-selected": "None selected",
    "select-llm": "You need to select an LLM",
    "search-llm": "Search all LLM providers",
  },

  "llm-provider": {
    openai: "The standard option for most non-commercial use.",
    azure: "The enterprise option of OpenAI hosted on Azure services.",
    anthropic: "A friendly AI Assistant hosted by Anthropic.",
    gemini: "Google's largest and most capable AI model",
    huggingface:
      "Access 150,000+ open-source LLMs and the world's AI community",
    ollama: "Run LLMs locally on your own machine.",
    lmstudio:
      "Discover, download, and run thousands of cutting edge LLMs in a few clicks.",
    localai: "Run LLMs locally on your own machine.",
    togetherai: "Run open source models from Together AI.",
    mistral: "Run open source models from Mistral AI.",
    perplexityai:
      "Run powerful and internet-connected models hosted by Perplexity AI.",
    openrouter: "A unified interface for LLMs.",
    groq: "The fastest LLM inferencing available for real-time AI applications.",
    koboldcpp: "Run local LLMs using koboldcpp.",
    oobabooga: "Run local LLMs using Oobabooga's Text Generation Web UI.",
    cohere: "Run Cohere's powerful Command models.",
    lite: "Run LiteLLM's OpenAI compatible proxy for various LLMs.",
    "generic-openai":
      "Connect to any OpenAi-compatible service via a custom configuration",
    native:
      "Use a downloaded custom Llama model for chatting on this instance.",
  },

  // audio preference
  audio: {
    title: "Speech-to-text Preference",
    provider: "Provider",
    "desc-speech":
      "Here you can specify what kind of text-to-speech and speech-to-text providers you would want to use in your platform experience. By default, we use the browser's built in support for these services, but you may want to use others.",
    "title-text": "Text-to-speech Preference",
    "desc-text":
      "Here you can specify what kind of text-to-speech providers you would want to use in your platform experience. By default, we use the browser's built in support for these services, but you may want to use others.",
    "desc-config": "There is no configuration needed for this provider.",
    "placeholder-stt": "Search speech to text providers",
    "placeholder-tts": "Search text to speech providers",
    "native-stt": "Uses your browser's built in STT service if supported.",
    "native-tts": "Uses your browser's built in TTS service if supported.",
    openai: "Use OpenAI's text to speech voices.",
    elevenlabs: "Use ElevenLabs's text to speech voices and technology.",
  },

  transcription: {
    title: "Transcription Model Preference",
    description:
      "These are the credentials and settings for your preferred transcription model provider. Its important these keys are current and correct or else media files and audio will not transcribe.",
    provider: "Transcription Provider",
    "warn-start":
      "Using the local whisper model on machines with limited RAM or CPU can stall the platform when processing media files.",
    "warn-recommend":
      "We recommend at least 2GB of RAM and upload files <10Mb.",
    "warn-end":
      "The built-in model will automatically download on the first use.",
    "search-audio": "Search audio transcription providers",
  },

  embedding: {
    title: "Preferencje Embedding",
    "desc-start":
      "Podczas korzystania z LLM, który nie obsługuje natywnie silnika embedding - może być konieczne podanie dodatkowych danych uwierzytelniających do osadzania tekstu.",
    "desc-end":
      "Embedding to proces zamiany tekstu na wektory. Te dane uwierzytelniające są wymagane do przekształcania plików i promptów na format, który platforma może wykorzystać do przetwarzania.",
    provider: {
      title: "Dostawca Embedding",
      description:
        "Nie jest wymagane żadne konfiguracja podczas korzystania z natywnego silnika embedding platformy.",
      "search-embed": "Wyszukaj wszystkich dostawców embedding",
    },
  },

  text: {
    title: "Preferencje Dzielania i Chunkowania Tekstu",
    "desc-start":
      "Czasami możesz chcieć zmienić domyślny sposób, w jaki nowe dokumenty są dzielone i chunkowane przed ich wprowadzeniem do bazy danych wektorów.",
    "desc-end":
      "Powinieneś modyfikować to ustawienie tylko wtedy, gdy rozumiesz, jak działa dzielenie tekstu i jakie są jego skutki.",
    "warn-start": "Zmiany tutaj będą dotyczyć tylko",
    "warn-center": "nowo osadzonych dokumentów",
    "warn-end": ", a nie istniejących dokumentów.",
    size: {
      title: "Rozmiar Chunków Tekstu",
      description:
        "To maksymalna długość znaków, które mogą być obecne w jednym wektorze.",
      recommend: "Maksymalna długość modelu osadzania wynosi",
    },

    overlap: {
      title: "Nakładanie Chunków Tekstu",
      description:
        "To maksymalne nakładanie się znaków podczas chunkowania między dwoma sąsiednimi chunkami tekstu.",
    },
  },

  // Vector Database
  vector: {
    title: "Baza Danych Wektorów",
    description:
      "To są dane uwierzytelniające i ustawienia, które określają funkcjonowanie instancji platformy. Ważne, aby te dane były aktualne i poprawne.",
    provider: {
      title: "Dostawca Bazy Danych Wektorów",
      description: "Nie jest wymagane żadne konfiguracja dla LanceDB.",
      "search-db": "Wyszukaj wszystkich dostawców bazy danych wektorów",
    },
  },

  // Embeddable Chat Widgets
  embeddable: {
    title: "Osadzalne Widgety Czatowe",
    description:
      "Osadzalne widgety czatowe to publiczne interfejsy czatowe powiązane z pojedynczą przestrzenią roboczą. Pozwalają one budować przestrzenie robocze, które można publikować na świecie.",
    create: "Utwórz osadzenie",
    table: {
      workspace: "Przestrzeń Robocza",
      chats: "Wysłane Czaty",
      Active: "Aktywne Domeny",
    },
  },

  "embed-chats": {
    title: "Osadzone Czaty",
    export: "Eksportuj",
    description:
      "To wszystkie zapisane czaty i wiadomości z dowolnego osadzenia, które opublikowałeś.",
    table: {
      embed: "Osadzenie",
      sender: "Nadawca",
      message: "Wiadomość",
      response: "Odpowiedź",
      at: "Wysłane o",
    },
  },

  multi: {
    title: "Tryb Multi-Użytkownika",
    description:
      "Skonfiguruj swoją instancję, aby wspierać swój zespół, aktywując Tryb Multi-Użytkownika.",
    enable: {
      "is-enable": "Tryb Multi-Użytkownika jest włączony",
      enable: "Włącz Tryb Multi-Użytkownika",
      description:
        "Domyślnie będziesz jedynym administratorem. Jako administrator musisz tworzyć konta dla wszystkich nowych użytkowników lub administratorów. Nie zgub hasła, ponieważ tylko użytkownik Administrator może resetować hasła.",
      username: "Nazwa użytkownika konta administratora",
      password: "Hasło konta administratora",
    },
    password: {
      title: "Ochrona Hasłem",
      description:
        "Chroń swoją instancję hasłem. Jeśli je zapomnisz, nie ma metody odzyskiwania, więc upewnij się, że zapisujesz to hasło.",
    },
    instance: {
      title: "Ochrona Instancji Hasłem",
      description:
        "Domyślnie będziesz jedynym administratorem. Jako administrator musisz tworzyć konta dla wszystkich nowych użytkowników lub administratorów. Nie zgub hasła, ponieważ tylko użytkownik Administrator może resetować hasła.",
      password: "Hasło instancji",
    },
  },

  // Event Logs
  event: {
    title: "Dzienniki Wydarzeń",
    description:
      "Zobacz wszystkie akcje i wydarzenia zachodzące na tej instancji w celu monitorowania.",
    clear: "Wyczyść Dzienniki Wydarzeń",
    table: {
      type: "Typ Wydarzenia",
      user: "Użytkownik",
      occurred: "Wystąpiło O",
    },
  },

  // Privacy & Data-Handling
  privacy: {
    title: "Prywatność i Obsługa Danych",
    description:
      "To jest konfiguracja dotycząca sposobu, w jaki podłączeni dostawcy zewnętrzni i nasza platforma obsługują Twoje dane.",
    llm: "Wybór LLM",
    embedding: "Preferencje Embedding",
    vector: "Baza Danych Wektorów",
    anonymous: "Anonimowa Telemetria Włączona",
    "desc-event": "Wszystkie wydarzenia nie zapisują adresu IP i zawierają",
    "desc-id": "żadnych identyfikujących",
    "desc-cont":
      "treści, ustawień, czatów ani innych informacji niezwiązanych z użytkowaniem. Aby zobaczyć listę zbieranych tagów wydarzeń, możesz zajrzeć na",
    "desc-git": "Github tutaj",
    "desc-end":
      "Jako projekt open-source szanujemy Twoje prawo do prywatności. Jesteśmy zobowiązani do budowania najlepszych rozwiązań integrujących AI i dokumenty w sposób prywatny i bezpieczny. Jeśli zdecydujesz się wyłączyć telemetrię, prosimy o przesłanie nam opinii, abyśmy mogli nadal ulepszać platformę dla Ciebie.",
  },

  "default-chat": {
    welcome: "Witamy w IST Legal.",
    "choose-legal": "Wybierz dziedzinę prawną po lewej stronie.",
  },

  invites: {
    title: "Zaproszenia",
    description:
      "Twórz linki zaproszeniowe dla osób w Twojej organizacji, aby mogły zaakceptować i zarejestrować się. Zaproszenia mogą być używane tylko przez jednego użytkownika.",
    link: "Utwórz Link Zaproszeniowy",
    accept: "Zaakceptowane Przez",
    "created-by": "Utworzone Przez",
    created: "Utworzone",
    new: {
      title: "Utwórz nowe zaproszenie",
      "desc-start":
        "Po utworzeniu będziesz mógł skopiować zaproszenie i wysłać je nowemu użytkownikowi, który będzie mógł utworzyć konto jako",
      "desc-mid": "domyślna",
      "desc-end":
        "rola i automatycznie zostanie dodany do wybranych przestrzeni roboczych.",
      "auto-add": "Automatycznie dodaj zaproszonego do przestrzeni roboczych",
      "desc-add":
        "Opcjonalnie możesz automatycznie przypisać użytkownika do poniższych przestrzeni roboczych, wybierając je. Domyślnie użytkownik nie będzie miał dostępu do żadnych przestrzeni roboczych. Możesz przypisać przestrzenie robocze później, po zaakceptowaniu zaproszenia.",
      cancel: "Anuluj",
      "create-invite": "Utwórz Zaproszenie",
      error: "Błąd: ",
    },
    "link-copied": "Link zaproszenia skopiowany",
    "copy-link": "Kopiuj Link Zaproszeniowy",
    "delete-invite-confirmation":
      "Czy na pewno chcesz dezaktywować to zaproszenie?\nPo wykonaniu tej czynności zaproszenie nie będzie już używalne.\n\nTa operacja jest nieodwracalna.",
    status: {
      label: "Status",
      pending: "Oczekujące",
      disabled: "Wyłączone",
      claimed: "Zaakceptowane",
    },
  },

  "user-menu": {
    edit: "Edytuj Konto",
    profile: "Zdjęcie Profilowe",
    size: "800 x 800",
    "remove-profile": "Usuń Zdjęcie Profilowe",
    username: "Adres e-mail",
    "username-placeholder": "Wprowadź adres e-mail",
    "new-password": "Nowe Hasło",
    "new-password-placeholder": "Nowe hasło",
    cancel: "Anuluj",
    update: "Zaktualizuj Konto",
    language: "Preferowany język",
    email: "Adres e-mail",
    "email-placeholder": "Wprowadź adres e-mail",
  },

  sidebar: {
    thread: {
      "load-thread": "ładowanie wątków...",
      "starting-thread": "Rozpoczynanie Wątku...",
      thread: "Nowy Wątek",
      delete: "Usuń Wybrane",
      rename: "Zmień Nazwę",
      "delete-thread": "Usuń Wątek",
      deleted: "usunięte",
      default: "domyślny",
      "empty-thread": "Wątek",
      "rename-message": "Jak chcesz nazwać ten wątek?",
      "delete-message":
        "Czy na pewno chcesz usunąć ten wątek? Wszystkie jego czaty zostaną usunięte. Nie można tego cofnąć.",
    },
  },

  embeder: {
    allm: "Użyj wbudowanego dostawcy embedding. Zero konfiguracji!",
    openai: "Standardowa opcja dla większości niekomercyjnych zastosowań.",
    azure: "Opcja korporacyjna OpenAI hostowana na usługach Azure.",
    localai: "Uruchamiaj modele embedding lokalnie na własnym urządzeniu.",
    ollama: "Uruchamiaj modele embedding lokalnie na własnym urządzeniu.",
    lmstudio:
      "Odkrywaj, pobieraj i uruchamiaj tysiące najnowocześniejszych modeli LLM za pomocą kilku kliknięć.",
    cohere: "Uruchamiaj potężne modele embedding z Cohere.",
    voyageai: "Uruchamiaj potężne modele embedding z Voyage AI.",
  },

  vectordb: {
    lancedb:
      "W 100% lokalna baza danych wektorów działająca na tej samej instancji, co platforma.",
    chroma:
      "Open-source'owa baza danych wektorów, którą możesz hostować lokalnie lub w chmurze.",
    pinecone:
      "W 100% oparta na chmurze baza danych wektorów dla zastosowań korporacyjnych.",
    zilliz:
      "Baza danych wektorów hostowana w chmurze, zbudowana dla przedsiębiorstw, zgodna z SOC 2.",
    qdrant:
      "Open-source'owa lokalna i rozproszona baza danych wektorów w chmurze.",
    weaviate:
      "Open-source'owa lokalna i hostowana w chmurze multimodalna baza danych wektorów.",
    milvus: "Open-source'owa, wysoce skalowalna i niezwykle szybka.",
    astra: "Wyszukiwanie wektorów dla rzeczywistych zastosowań GenAI.",
  },

  system: {
    title: "Preferencje Systemowe",
    "desc-start": "To są ogólne ustawienia i konfiguracje Twojej instancji.",
    user: "Użytkownicy mogą usuwać przestrzenie robocze",
    "desc-delete":
      "Zezwalaj użytkownikom niebędącym administratorami na usuwanie przestrzeni roboczych, do których należą. Spowoduje to usunięcie przestrzeni roboczej dla wszystkich.",
    limit: {
      title: "Ogranicz liczbę wiadomości na użytkownika dziennie",
      "desc-limit":
        "Ogranicz użytkowników niebędących administratorami do określonej liczby zapytań lub czatów w ciągu 24 godzin. Włącz to, aby zapobiec wysokim kosztom OpenAI.",
      "per-day": "Limit wiadomości dziennie",
      label: "Ogranicz wiadomości: ",
    },
    state: {
      enabled: "Włączone",
      disabled: "Wyłączone",
    },
    "source-highlighting": {
      title: "Włącz/Wyłącz podświetlanie źródeł",
      description: "Ukryj lub pokaż podświetlanie źródeł użytkownikom.",
      label: "Cytowanie: ",
    },
    save: "Zapisz zmiany",
  },
  "user-setting": {
    description:
      "To są wszystkie konta, które mają dostęp do tej instancji. Usunięcie konta natychmiast usunie ich dostęp do tej instancji.",
    "add-user": "Dodaj użytkownika",
    username: "Nazwa użytkownika",
    role: "Rola",
    default: "Domyślna",
    manager: "Menadżer",
    admin: "Administrator",
    "date-added": "Data dodania",
    "all-domains": "Wszystkie domeny",
    "other-users": "Inni użytkownicy (Bez domeny)",
    edit: "Edytuj: ",
    "new-password": "Nowe hasło",
    "password-rule": "Hasło musi mieć co najmniej 8 znaków.",
    "update-user": "Aktualizuj użytkownika",
    placeholder: "Nazwa użytkownika",
    cancel: "Anuluj",
    "remove-user": "Usuń użytkownika",
    "remove-user-title": "Usuń użytkownika",
    "remove-user-confirmation": "Czy na pewno chcesz usunąć tego użytkownika?",
    error: "Błąd: ",
  },

  support: {
    title: "E-mail Wsparcia",
    description:
      "Ustaw adres e-mail wsparcia, który będzie widoczny w menu użytkownika podczas logowania do tej instancji.",
    clear: "Wyczyść",
    save: "Zapisz",
  },
  "public-mode": {
    enable: "Włącz Tryb Publiczny",
    enabled: "Tryb Publiczny jest Włączony",
  },
  button: {
    delete: "Usuń",
    edit: "Edytuj",
    suspend: "Zawieś",
    unsuspend: "Wznów",
    save: "Zapisz",
    accept: "Akceptuj",
    decline: "Odrzuć",
    "flush-vector-caches": "Wyczyść pamięć podręczną wektorów",
  },

  "new-user": {
    title: "Dodaj użytkownika do instancji",
    username: "Nazwa użytkownika",
    "username-ph": "Nazwa użytkownika",
    password: "Hasło",
    "password-ph": "Początkowe hasło użytkownika",
    role: "Rola",
    default: "Domyślna",
    manager: "Menadżer",
    admin: "Administrator",
    description:
      "Po utworzeniu użytkownik będzie musiał zalogować się za pomocą początkowego loginu, aby uzyskać dostęp.",
    cancel: "Anuluj",
    "add-User": "Dodaj użytkownika",
    error: "Błąd: ",
    "invalid-email": "Proszę podać prawidłowy adres e-mail.",
    permissions: {
      title: "Uprawnienia",
      default: [
        "Może wysyłać czaty tylko w przestrzeniach roboczych, do których zostali dodani przez administratorów lub menadżerów.",
        "Nie może w ogóle modyfikować ustawień.",
      ],
      manager: [
        "Może przeglądać, tworzyć i usuwać dowolne przestrzenie robocze oraz modyfikować ustawienia specyficzne dla przestrzeni roboczych.",
        "Może tworzyć, aktualizować i zapraszać nowych użytkowników do instancji.",
        "Nie może modyfikować połączeń LLM, bazy danych wektorów, embedding ani innych połączeń.",
      ],
      admin: [
        "Najwyższy poziom uprawnień użytkownika.",
        "Może widzieć i wykonywać wszystko w całym systemie.",
      ],
    },
  },

  "new-embed": {
    title: "Utwórz nowe osadzenie dla przestrzeni roboczej",
    error: "Błąd: ",
    "desc-start":
      "Po utworzeniu osadzenia otrzymasz link, który możesz opublikować na swojej stronie za pomocą prostego",
    script: "skryptu",
    tag: "tagu.",
    cancel: "Anuluj",
    "create-embed": "Utwórz osadzenie",
    workspace: "Przestrzeń robocza",
    "desc-workspace":
      "To jest przestrzeń robocza, na której będzie opierać się Twoje okno czatu. Wszystkie domyślne ustawienia będą dziedziczone z przestrzeni roboczej, chyba że zostaną nadpisane przez tę konfigurację.",
    "allowed-chat": "Dozwolona metoda czatu",
    "desc-query":
      "Ustaw, jak powinien działać Twój chatbot. Tryb zapytań oznacza, że odpowie tylko wtedy, gdy dokument pomoże odpowiedzieć na zapytanie.",
    "desc-chat":
      "Czat otwiera rozmowę na pytania ogólne i może odpowiadać na zapytania niezwiązane z przestrzenią roboczą.",
    "desc-response":
      "Czat: Odpowiada na wszystkie pytania niezależnie od kontekstu",
    "query-response":
      "Zapytanie: Odpowiada tylko na czaty związane z dokumentami w przestrzeni roboczej",
    restrict: "Ogranicz żądania z domen",
    filter:
      "Ten filtr zablokuje wszelkie żądania pochodzące z domeny innej niż wymienione poniżej.",
    "use-embed":
      "Pozostawienie tego pola pustego oznacza, że każdy może używać Twojego osadzenia na dowolnej stronie.",
    "max-chats": "Maksymalna liczba czatów dziennie",
    "limit-chats":
      "Ogranicz liczbę czatów, które osadzony czat może obsłużyć w ciągu 24 godzin. Zero oznacza brak ograniczeń.",
    "chats-session": "Maksymalna liczba czatów na sesję",
    "limit-chats-session":
      "Ogranicz liczbę czatów, które użytkownik sesji może wysłać za pomocą tego osadzenia w ciągu 24 godzin. Zero oznacza brak ograniczeń.",
    "enable-dynamic": "Włącz dynamiczne użycie modelu",
    "llm-override":
      "Zezwalaj na ustawienie preferowanego modelu LLM, aby nadpisać domyślną konfigurację przestrzeni roboczej.",
    "llm-temp": "Włącz dynamiczną temperaturę LLM",
    "desc-temp":
      "Zezwalaj na ustawienie temperatury LLM, aby nadpisać domyślną konfigurację przestrzeni roboczej.",
    "prompt-override": "Włącz nadpisywanie promptu",
    "desc-override":
      "Zezwalaj na ustawienie promptu systemowego, aby nadpisać domyślną konfigurację przestrzeni roboczej.",
  },

  "show-toast": {
    "recovery-codes": "Kody odzyskiwania skopiowane do schowka",
    "scraping-website": "Pobieranie danych z witryny - może to chwilę potrwać.",
    "fetching-transcript": "Pobieranie transkrypcji dla filmu YouTube.",
    "updating-workspace": "Aktualizowanie przestrzeni roboczej...",
    "workspace-updated": "Przestrzeń robocza zaktualizowana pomyślnie.",
    "link-uploaded": "Link przesłany pomyślnie",
    "password-reset": "Hasło zresetowane pomyślnie",
    "invalid-reset": "Nieprawidłowy token resetowania",
    "delete-option": "Nie udało się usunąć wątku!",
    "thread-deleted": "Wątek usunięty pomyślnie!",
    "picture-uploaded": "Zdjęcie profilowe przesłane.",
    "profile-updated": "Profil zaktualizowany.",
    "logs-cleared": "Dzienniki zdarzeń wyczyszczone pomyślnie.",
    "preferences-updated": "Preferencje systemowe zaktualizowane pomyślnie.",
    "user-created": "Użytkownik utworzony pomyślnie.",
    "user-creation-error": "Nie udało się utworzyć użytkownika: ",
    "user-deleted": "Użytkownik usunięty z systemu.",
    "workspaces-saved": "Przestrzenie robocze zapisane pomyślnie!",
    "failed-workspaces":
      "Nie udało się zapisać przestrzeni roboczych. Spróbuj ponownie.",
    "api-deleted": "Klucz API usunięty na stałe",
    "api-copied": "Klucz API skopiowany do schowka",
    "appname-updated": "Pomyślnie zaktualizowano nazwę aplikacji.",
    "appname-update-error": "Nie udało się zaktualizować nazwy aplikacji: ",
    "language-updated": "Język zaktualizowany pomyślnie.",
    "palette-updated": "Paleta kolorów zaktualizowana pomyślnie.",
    "image-uploaded": "Obraz przesłany pomyślnie.",
    "logo-remove-error": "Błąd podczas usuwania logo: ",
    "logo-removed": "Logo usunięte pomyślnie.",
    "logo-uploaded": "Logo przesłane pomyślnie.",
    "logo-upload-error": "Nie udało się przesłać logo: ",
    "updated-welcome": "Pomyślnie zaktualizowano wiadomości powitalne.",
    "update-welcome-error":
      "Nie udało się zaktualizować wiadomości powitalnych:",
    "updated-footer": "Pomyślnie zaktualizowano ikony stopki.",
    "update-footer-error": "Nie udało się zaktualizować ikon stopki: ",
    "updated-paragraph": "Pomyślnie zaktualizowano niestandardowy tekst.",
    "update-paragraph-error":
      "Nie udało się zaktualizować niestandardowego tekstu: ",
    "updated-supportemail": "Pomyślnie zaktualizowano e-mail wsparcia.",
    "update-supportemail-error":
      "Nie udało się zaktualizować e-maila wsparcia: ",
    "stt-success": "Preferencje rozpoznawania mowy zapisane pomyślnie.",
    "tts-success": "Preferencje syntezatora mowy zapisane pomyślnie.",
    "failed-chats-export": "Nie udało się wyeksportować czatów.",
    "chats-exported": "Czaty wyeksportowane pomyślnie jako {{name}}.",
    "cleared-chats": "Wyczyszczono wszystkie czaty.",
    "embed-deleted": "Osadzenie usunięte z systemu.",
    "snippet-copied": "Fragment skopiowany do schowka!",
    "embed-updated": "Osadzenie zaktualizowane pomyślnie.",
    "embedding-saved": "Preferencje embedding zapisane pomyślnie.",
    "chunking-settings": "Strategia dzielenia tekstu zapisana pomyślnie.",
    "llm-saved": "Preferencje LLM zapisane pomyślnie.",
    "multiuser-enabled": "Tryb Multi-Użytkownika włączony pomyślnie.",
    "publicuser-enabled": "Tryb Użytkownika Publicznego włączony pomyślnie.",
    "publicuser-disabled": "Tryb Użytkownika Publicznego wyłączony pomyślnie.",
    "page-refresh": "Twoja strona odświeży się za kilka sekund.",
    "transcription-saved": "Preferencje transkrypcji zapisane pomyślnie.",
    "vector-saved": "Preferencje bazy danych wektorów zapisane pomyślnie.",
    "workspace-not-deleted": "Nie udało się usunąć przestrzeni roboczej!",
    "maximum-messages": "Maksymalnie 4 wiadomości dozwolone.",
    "users-updated": "Użytkownicy zaktualizowani pomyślnie.",
    "vectordb-not-reset": "Nie udało się zresetować bazy danych wektorów!",
    "vectordb-reset": "Baza danych wektorów zresetowana pomyślnie!",
    "meta-data-update": "Preferencje strony zaktualizowane!",
    "linked-workspaces-updated":
      "Połączone przestrzenie robocze zaktualizowane pomyślnie.",
    "upgrade-answer-error": "Nie udało się ulepszyć odpowiedzi: ",
    "reset-tab-name-error":
      "Nie udało się zresetować do domyślnej nazwy karty.",
    "update-tab-name-error": "Nie udało się zaktualizować nazw kart: ",
    "updated-website": "Ustawienia strony zaktualizowane pomyślnie.",
    "update-website-error": "Nie udało się zaktualizować linku strony: ",
    "reset-website-error":
      "Nie udało się zresetować do domyślnego linku strony.",
    "palette-update-error": "Nie udało się zaktualizować palety kolorów: ",
    "citation-state-updated":
      "Stan cytowania zaktualizowany pomyślnie. {{citationState}}",
    "citation-state-update-error":
      "Nie udało się zaktualizować ustawienia cytowania",
    "citation-update-error": "Błąd podczas przesyłania ustawienia cytowania",
    "message-limit-updated":
      "Preferencje limitu wiadomości zaktualizowane pomyślnie.",
    "flashing-started": "Rozpoczęto flashowanie...",
    "flashing-success": "Flashowanie zakończone pomyślnie",
    "flashing-error": "Błąd podczas flashowania: {{error}}",
  },

  "llm-selection-privacy": {
    openai: {
      description: [
        "Twoje czaty nie będą używane do szkolenia",
        "Twoje prompty i teksty dokumentów używane do tworzenia odpowiedzi są widoczne dla OpenAI",
      ],
    },
    azure: {
      description: [
        "Twoje czaty nie będą używane do szkolenia",
        "Twoje teksty i osadzone teksty nie są widoczne dla OpenAI ani Microsoft",
      ],
    },
    anthropic: {
      description: [
        "Twoje czaty nie będą używane do szkolenia",
        "Twoje prompty i teksty dokumentów używane do tworzenia odpowiedzi są widoczne dla Anthropic",
      ],
    },
    gemini: {
      description: [
        "Twoje czaty są anonimizowane i używane do szkolenia",
        "Twoje prompty i teksty dokumentów używane do tworzenia odpowiedzi są widoczne dla Google",
      ],
    },
    lmstudio: {
      description: [
        "Twój model i czaty są dostępne tylko na serwerze uruchamiającym LMStudio",
      ],
    },
    localai: {
      description: [
        "Twój model i czaty są dostępne tylko na serwerze uruchamiającym LocalAI",
      ],
    },
    ollama: {
      description: [
        "Twój model i czaty są dostępne tylko na maszynie uruchamiającej modele Ollama",
      ],
    },
    native: {
      description: ["Twój model i czaty są dostępne tylko na tej instancji"],
    },
    togetherai: {
      description: [
        "Twoje czaty nie będą używane do szkolenia",
        "Twoje prompty i teksty dokumentów używane do tworzenia odpowiedzi są widoczne dla TogetherAI",
      ],
    },
    mistral: {
      description: [
        "Twoje prompty i teksty dokumentów używane do tworzenia odpowiedzi są widoczne dla Mistral",
      ],
    },
    huggingface: {
      description: [
        "Twoje prompty i teksty dokumentów używane do odpowiedzi są przesyłane do zarządzanego punktu końcowego HuggingFace",
      ],
    },
    perplexity: {
      description: [
        "Twoje czaty nie będą używane do szkolenia",
        "Twoje prompty i teksty dokumentów używane do tworzenia odpowiedzi są widoczne dla Perplexity AI",
      ],
    },
    openrouter: {
      description: [
        "Twoje czaty nie będą używane do szkolenia",
        "Twoje prompty i teksty dokumentów używane do tworzenia odpowiedzi są widoczne dla OpenRouter",
      ],
    },
    groq: {
      description: [
        "Twoje czaty nie będą używane do szkolenia",
        "Twoje prompty i teksty dokumentów używane do tworzenia odpowiedzi są widoczne dla Groq",
      ],
    },
    koboldcpp: {
      description: [
        "Twój model i czaty są dostępne tylko na serwerze uruchamiającym KoboldCPP",
      ],
    },
    textgenwebui: {
      description: [
        "Twój model i czaty są dostępne tylko na serwerze uruchamiającym interfejs Oobabooga Text Generation Web UI",
      ],
    },
    "generic-openai": {
      description: [
        "Dane są udostępniane zgodnie z warunkami usługi obowiązującymi u Twojego dostawcy ogólnego punktu końcowego.",
      ],
    },
    cohere: {
      description: [
        "Dane są udostępniane zgodnie z warunkami usługi cohere.com oraz lokalnymi przepisami o ochronie prywatności.",
      ],
    },
    litellm: {
      description: [
        "Twój model i czaty są dostępne tylko na serwerze uruchamiającym LiteLLM",
      ],
    },
  },

  "vector-db-privacy": {
    chroma: {
      description: [
        "Twoje wektory i teksty dokumentów są przechowywane na Twojej instancji Chroma",
        "Dostęp do Twojej instancji jest zarządzany przez Ciebie",
      ],
    },
    pinecone: {
      description: [
        "Twoje wektory i teksty dokumentów są przechowywane na serwerach Pinecone",
        "Dostęp do Twoich danych jest zarządzany przez Pinecone",
      ],
    },
    qdrant: {
      description: [
        "Twoje wektory i teksty dokumentów są przechowywane na Twojej instancji Qdrant (w chmurze lub lokalnie)",
      ],
    },
    weaviate: {
      description: [
        "Twoje wektory i teksty dokumentów są przechowywane na Twojej instancji Weaviate (w chmurze lub lokalnie)",
      ],
    },
    milvus: {
      description: [
        "Twoje wektory i teksty dokumentów są przechowywane na Twojej instancji Milvus (w chmurze lub lokalnie)",
      ],
    },
    zilliz: {
      description: [
        "Twoje wektory i teksty dokumentów są przechowywane w chmurowym klastrze Zilliz.",
      ],
    },
    astra: {
      description: [
        "Twoje wektory i teksty dokumentów są przechowywane w chmurowej bazie danych AstraDB.",
      ],
    },
    lancedb: {
      description: [
        "Twoje wektory i teksty dokumentów są przechowywane prywatnie na tej instancji platformy",
      ],
    },
  },

  "embedding-engine-privacy": {
    native: {
      description: [
        "Twoje teksty dokumentów są osadzane prywatnie na tej instancji platformy",
      ],
    },
    openai: {
      description: [
        "Twoje teksty dokumentów są przesyłane na serwery OpenAI",
        "Twoje dokumenty nie są używane do szkolenia",
      ],
    },
    azure: {
      description: [
        "Twoje teksty dokumentów są przesyłane do Twojej usługi Microsoft Azure",
        "Twoje dokumenty nie są używane do szkolenia",
      ],
    },
    localai: {
      description: [
        "Twoje teksty dokumentów są osadzane prywatnie na serwerze uruchamiającym LocalAI",
      ],
    },
    ollama: {
      description: [
        "Twoje teksty dokumentów są osadzane prywatnie na serwerze uruchamiającym Ollama",
      ],
    },
    lmstudio: {
      description: [
        "Twoje teksty dokumentów są osadzane prywatnie na serwerze uruchamiającym LMStudio",
      ],
    },
    cohere: {
      description: [
        "Dane są udostępniane zgodnie z warunkami usługi cohere.com oraz lokalnymi przepisami o ochronie prywatności.",
      ],
    },
    voyageai: {
      description: [
        "Dane przesyłane na serwery Voyage AI są udostępniane zgodnie z warunkami usługi voyageai.com.",
      ],
    },
  },

  "prompt-validate": {
    edit: "Edytuj",
    response: "Odpowiedź",
    prompt: "Prompt",
    regenerate: "Wygeneruj odpowiedź ponownie",
    good: "Dobra odpowiedź",
    bad: "Zła odpowiedź",
    copy: "Kopiuj",
    more: "Więcej akcji",
    fork: "Rozgałęzienie",
    delete: "Usuń",
    cancel: "Anuluj",
    save: "Zapisz i Wyślij",
  },

  citations: {
    show: "Pokaż Cytowania",
    hide: "Ukryj Cytowania",
    chunk: "Fragmenty Cytowań",
    pdr: "Dokument Macierzysty",
    "pdr-h": "Podświetlanie Dokumentu",
    referenced: "Odniesiono",
    times: "razy.",
    citation: "Cytowanie",
    match: "dopasowanie",
    download:
      "Twoja przeglądarka nie obsługuje plików PDF. Pobierz plik PDF, aby go wyświetlić:",
    "download-btn": "Pobierz PDF",
  },

  "document-drafting": {
    title: "Tworzenie Dokumentów",
    description: "Zarządzaj ustawieniami tworzenia dokumentów.",
    configuration: "Konfiguracja",
    "drafting-model": "Model Tworzenia Dokumentów",
    enabled: "Tworzenie Dokumentów jest włączone",
    disabled: "Tworzenie Dokumentów jest wyłączone",
    "enabled-toast": "Tworzenie Dokumentów włączone",
    "disabled-toast": "Tworzenie Dokumentów wyłączone",
    "desc-settings":
      "Administrator może zmienić ustawienia tworzenia dokumentów dla wszystkich użytkowników.",
    "drafting-llm": "Preferencje Modelu Tworzenia Dokumentów",
    saving: "Zapisywanie...",
    save: "Zapisz zmiany",
    "chat-settings": "Ustawienia Czatu",
    "drafting-chat-settings": "Ustawienia Czatu dla Tworzenia Dokumentów",
    "chat-settings-desc":
      "Kontroluj zachowanie funkcji czatu dla tworzenia dokumentów.",
    "drafting-prompt": "Systemowy Prompt do Tworzenia Dokumentów",
    "drafting-prompt-desc":
      "Systemowy prompt używany do tworzenia dokumentów różni się od promptu systemowego Q&A prawnego. Prompt do tworzenia dokumentów definiuje kontekst i instrukcje dla AI, aby generować odpowiedzi. Powinieneś podać starannie sformułowany prompt, aby AI mogło wygenerować trafną i dokładną odpowiedź.",
    linking: "Łączenie Dokumentów",
    "legal-issues-prompt": "Prompt dla Kwestii Prawnych",
    "legal-issues-prompt-desc": "Wprowadź prompt dla kwestii prawnych.",
    "memo-prompt": "Prompt dla Memo",
    "memo-prompt-desc": "Wprowadź prompt dla memo.",
    "desc-linkage":
      "Włącz dodawanie dalszego kontekstu prawnego poprzez wyszukiwanie Vector/PDR oprócz pobierania memo.",
  },

  modale: {
    document: {
      title: "Moje Dokumenty",
      document: "Dokumenty",
      search: "Szukaj dokumentu",
      folder: "Nowy Folder",
      name: "Nazwa",
      empty: "Brak Dokumentów",
      "move-workspace": "Przenieś do Przestrzeni Roboczej",
      "doc-processor": "Procesor Dokumentów Niedostępny",
      "processor-offline":
        "Nie możemy przesłać Twoich plików, ponieważ procesor dokumentów jest offline. Spróbuj ponownie później.",
      "drag-drop": "Kliknij, aby przesłać lub przeciągnij i upuść",
      "supported-files":
        "obsługuje pliki tekstowe, csv, arkusze kalkulacyjne, pliki audio i więcej!",
      "submit-link": "lub podaj link",
      fetch: "Pobierz stronę",
      fetching: "Pobieranie...",
      "file-desc":
        "Te pliki zostaną przesłane do procesora dokumentów działającego na tej instancji. Pliki te nie są przesyłane ani udostępniane podmiotom trzecim.",
      cost: "*Jednorazowy koszt osadzania",
      "save-embed": "Zapisz i Osadź",
      "failed-uploads": "Nie udało się przesłać następujących plików",
      "loading-message": "Może to chwilę potrwać dla dużych dokumentów",
    },
    connectors: {
      title: "Konektory Danych",
      search: "Szukaj konektorów danych",
      empty: "Nie znaleziono konektorów danych.",
    },
  },

  dataConnectors: {
    github: {
      name: "Repozytorium GitHub",
      description:
        "Importuj całe publiczne lub prywatne repozytorium GitHub jednym kliknięciem.",
      url: "URL Repozytorium GitHub",
      "collect-url": "URL repozytorium GitHub, które chcesz zebrać.",
      "access-token": "Token Dostępu GitHub",
      optional: "opcjonalny",
      "rate-limiting": "Token Dostępu, aby zapobiec ograniczeniom szybkości.",
      "desc-picker":
        "Po zakończeniu wszystkie pliki będą dostępne do osadzenia w przestrzeniach roboczych w selektorze dokumentów.",
      branch: "Gałąź",
      "branch-desc": "Gałąź, z której chcesz zebrać pliki.",
      "branch-loading": "-- ładowanie dostępnych gałęzi --",
      "desc-start": "Bez wypełnienia",
      "desc-token": "Token Dostępu GitHub",
      "desc-connector": "ten konektor danych będzie w stanie zebrać tylko",
      "desc-level": "pliki najwyższego poziomu",
      "desc-end": "repozytorium ze względu na ograniczenia API GitHub.",
      "personal-token":
        "Uzyskaj darmowy Token Dostępu Osobistego z kontem GitHub tutaj.",
      without: "Bez",
      "personal-token-access": "Tokenu Dostępu Osobistego",
      "desc-api":
        ", API GitHub może ograniczyć liczbę plików, które można zebrać, ze względu na ograniczenia szybkości. Możesz",
      "temp-token": "utworzyć tymczasowy Token Dostępu",
      "avoid-issue": "aby uniknąć tego problemu.",
      submit: "Zatwierdź",
      "collecting-files": "Zbieranie plików...",
    },
    "youtube-transcript": {
      name: "Transkrypcja YouTube",
      description:
        "Importuj transkrypcję całego filmu YouTube za pomocą linku.",
      url: "URL Filmu YouTube",
      "url-video": "URL filmu YouTube, który chcesz transkrybować.",
      collect: "Zbierz transkrypcję",
      collecting: "Zbieranie transkrypcji...",
      "desc-end":
        "po zakończeniu transkrypcja będzie dostępna do osadzenia w przestrzeniach roboczych w selektorze dokumentów.",
    },
    "website-depth": {
      name: "Hurtowy Scraper Linków",
      description:
        "Zeskrob stronę internetową i jej pod-linki do określonej głębokości.",
      url: "URL Strony Internetowej",
      "url-scrape": "URL strony internetowej, którą chcesz zeskrobać.",
      depth: "Głębokość",
      "child-links":
        "To liczba pod-linków, które pracownik powinien śledzić od początkowego URL.",
      "max-links": "Maksymalna Liczba Linków",
      "links-scrape": "Maksymalna liczba linków do zeskrobania.",
      scraping: "Zeskrobywanie strony internetowej...",
      submit: "Zatwierdź",
      "desc-scrap":
        "Po zakończeniu wszystkie zeskrobane strony będą dostępne do osadzenia w przestrzeniach roboczych w selektorze dokumentów.",
    },
    confluence: {
      name: "Confluence",
      description: "Importuj całą stronę Confluence jednym kliknięciem.",
      url: "URL Strony Confluence",
      "url-page": "URL strony w przestrzeni Confluence.",
      username: "Nazwa użytkownika Confluence",
      "own-username": "Twoja nazwa użytkownika Confluence.",
      token: "Token Dostępu Confluence",
      "desc-start":
        "Musisz podać token dostępu do uwierzytelnienia. Możesz wygenerować token dostępu",
      here: "tutaj",
      access: "Token dostępu do uwierzytelnienia.",
      collecting: "Zbieranie stron...",
      submit: "Zatwierdź",
      "desc-end":
        "Po zakończeniu wszystkie strony będą dostępne do osadzenia w przestrzeniach roboczych.",
    },
  },

  module: {
    "legal-qa": "Pytania i Odpowiedzi Prawne",
    "document-drafting": "Tworzenie Dokumentów",
  },

  "fine-tune": {
    title: "Masz wystarczająco dużo danych, aby dostroić model!",
    link: "kliknij, aby dowiedzieć się więcej",
    dismiss: "zamknij",
  },
  mobile: {
    disclaimer:
      "ZASTRZEŻENIE: Dla najlepszych wrażeń i pełnego dostępu do wszystkich funkcji zaleca się korzystanie z aplikacji na komputerze.",
  },
  onboarding: {
    welcome: "Witamy w",
    "get-started": "Rozpocznij",
    "llm-preference": {
      title: "Preferencje LLM",
      description:
        "ISTLLM współpracuje z wieloma dostawcami LLM. To będzie usługa obsługująca czaty.",
      "LLM-search": "Wyszukaj dostawców LLM",
    },
    "user-setup": {
      title: "Konfiguracja Użytkownika",
      description: "Skonfiguruj swoje ustawienia użytkownika.",
      "sub-title": "Ilu użytkowników będzie korzystać z tej instancji?",
      "single-user": "Tylko ja",
      "multiple-user": "Mój zespół",
      "setup-password": "Czy chcesz ustawić hasło?",
      "password-requirment": "Hasła muszą mieć co najmniej 8 znaków.",
      "save-password":
        "Ważne jest, aby zapisać to hasło, ponieważ nie ma metody odzyskiwania.",
      "password-label": "Hasło do Instancji",
      username: "Nazwa użytkownika konta administratora",
      password: "Hasło konta administratora",
      "account-requirment":
        "Nazwa użytkownika musi mieć co najmniej 6 znaków i zawierać tylko małe litery, cyfry, podkreślniki i myślniki bez spacji. Hasło musi mieć co najmniej 8 znaków.",
      "password-note":
        "Domyślnie będziesz jedynym administratorem. Po zakończeniu wprowadzenia możesz tworzyć i zapraszać innych jako użytkowników lub administratorów. Nie zgub hasła, ponieważ tylko administratorzy mogą je resetować.",
    },
    "data-handling": {
      title: "Obsługa Danych i Prywatność",
      description:
        "Jesteśmy zaangażowani w przejrzystość i kontrolę w kwestii Twoich danych osobowych.",
      "llm-label": "Wybór LLM",
      "embedding-label": "Preferencje Embedding",
      "database-lablel": "Baza Danych Wektorów",
      "reconfigure-option":
        "Te ustawienia można zmienić w dowolnym momencie w ustawieniach.",
    },
    survey: {
      title: "Witamy w IST Legal LLM",
      description:
        "Pomóż nam dostosować IST Legal LLM do Twoich potrzeb. Opcjonalne.",
      email: "Jaki jest Twój adres e-mail?",
      usage: "Do czego będziesz używać platformy?",
      work: "Do pracy",
      "personal-use": "Do użytku osobistego",
      other: "Inne",
      comment: "Jakieś uwagi dla zespołu?",
      optional: "(Opcjonalne)",
      feedback: "Dziękujemy za Twoją opinię!",
    },
    button: {
      yes: "Tak",
      no: "Nie",
      "skip-survey": "Pomiń Ankietę",
    },
    placeholder: {
      "admin-password": "Twoje hasło administratora",
      "admin-username": "Twoja nazwa użytkownika administratora",
      "email-example": "twojemail@gmail.com",
      comment:
        "Jeśli masz pytania lub uwagi, możesz je tutaj zostawić, a my skontaktujemy się z Tobą. Możesz również napisać na richard.sahlberg@foytech.se",
    },
  },
  "default-settings": {
    title: "Domyślne Ustawienia dla Pytania i Odpowiedzi Prawnych",
    "default-desc":
      "Kontroluj domyślne zachowanie przestrzeni roboczych dla Pytania i Odpowiedzi Prawnych",
    prompt: "Domyślny Prompt do Pytania i Odpowiedzi Prawnych",
    "prompt-desc":
      "Domyślny prompt, który będzie używany w Pytania i Odpowiedzi Prawnych. Zdefiniuj kontekst i instrukcje dla AI, aby wygenerować odpowiedź. Powinieneś podać starannie sformułowany prompt, aby AI mogło wygenerować trafną i dokładną odpowiedź.",
    "toast-success": "Domyślny systemowy prompt zaktualizowany",
    "toast-fail": "Nie udało się zaktualizować systemowego promptu",
    snippets: {
      title: "Domyślna Maksymalna Liczba Fragmentów Kontekstu",
      description:
        "To ustawienie kontroluje domyślną maksymalną liczbę fragmentów kontekstu, które będą wysyłane do LLM na czat lub zapytanie.",
      recommend: "Zalecane: 10",
    },
  },
  "confirm-message": {
    "delete-doc":
      "Czy na pewno chcesz usunąć te pliki i foldery?\nTo usunie pliki z systemu i automatycznie usunie je z istniejących przestrzeni roboczych.\nTa operacja jest nieodwracalna.",
  },

  "answer-upgrade": {
    title: "Ulepsz Odpowiedź",
    "category-step": {
      title: "Wybierz Kategorię",
      description:
        "Wybierz kategorię, która najlepiej odpowiada Twoim potrzebom",
      categories: {
        formality: {
          label: "Formalność",
          choices: {
            more_formal: "Uczyń to bardziej formalnym",
            less_formal: "Uczyń to mniej formalnym",
            more_professional: "Uczyń to bardziej profesjonalnym",
            more_casual: "Uczyń to bardziej swobodnym",
            more_polished: "Uczyń to bardziej dopracowanym",
            more_relaxed: "Uczyń to bardziej zrelaksowanym",
            academic_tone: "Użyj bardziej akademickiego tonu",
            conversational_tone: "Użyj bardziej rozmownego tonu",
          },
        },
        complexity: {
          label: "Złożoność Języka",
          choices: {
            simplify: "Uprość język",
            more_descriptive: "Dodaj bardziej opisowy język",
            complex_vocab: "Użyj bardziej złożonego słownictwa",
            simple_vocab: "Użyj prostszego słownictwa",
            technical: "Zwiększ użycie języka technicznego",
            layman: "Użyj bardziej przystępnych terminów",
            add_jargon: "Dodaj żargon specyficzny dla dziedziny",
            avoid_jargon: "Unikaj żargonu i używaj ogólnych terminów",
            add_rhetorical: "Dodaj więcej pytań retorycznych",
            less_rhetorical: "Użyj mniej pytań retorycznych",
          },
        },
        structure: {
          label: "Struktura Zdań",
          choices: {
            shorter: "Skróć zdania",
            longer: "Wydłuż zdania",
            vary: "Zróżnicuj strukturę zdań",
            standardize: "Ustandaryzuj strukturę zdań",
            more_complex: "Użyj bardziej złożonych zdań",
            simpler: "Użyj prostszych zdań",
            active_voice: "Zwiększ użycie strony czynnej",
            passive_voice: "Zwiększ użycie strony biernej",
          },
        },
        figurative: {
          label: "Język Figuralny",
          choices: {
            more_figurative: "Użyj więcej języka figuralnego",
            less_figurative: "Zredukuj użycie języka figuralnego",
            metaphors: "Dodaj więcej metafor i porównań",
            literal: "Użyj bardziej dosłownego języka",
            more_idioms: "Wprowadź więcej idiomów",
            less_idioms: "Zminimalizuj użycie idiomów",
            more_symbolism: "Zwiększ użycie symboliki",
            less_symbolism: "Zredukuj użycie symboliki",
          },
        },
        conciseness: {
          label: "Zwięzłość",
          choices: {
            more_concise: "Uczyń to bardziej zwięzłym",
            more_wordy: "Uczyń to bardziej rozwlekłym",
            remove_redundant: "Usuń zbędne frazy",
            add_details: "Dodaj więcej szczegółowych wyjaśnień",
            reduce_filler: "Zredukuj słowa wypełniające",
            add_elaboration: "Dodaj więcej rozwinięcia",
          },
        },
        imagery: {
          label: "Obrazowanie i Szczegóły Sensoryczne",
          choices: {
            enhance_imagery: "Zwiększ obrazowość",
            simplify_imagery: "Uprość obrazowość",
            vivid_descriptions: "Użyj bardziej żywych opisów",
            straightforward_descriptions: "Użyj bardziej bezpośrednich opisów",
            more_visual: "Dodaj więcej szczegółów wizualnych",
            less_visual: "Skup się mniej na szczegółach wizualnych",
          },
        },
        paragraph: {
          label: "Struktura Akapitów i Tekstu",
          choices: {
            shorter_paragraphs: "Uczyń akapity krótszymi",
            longer_paragraphs: "Uczyń akapity dłuższymi",
            break_sections: "Podziel tekst na mniejsze sekcje",
            combine_sections: "Połącz sekcje dla płynniejszego przepływu",
            more_lists: "Użyj więcej punktów i list",
            more_continuous: "Użyj bardziej ciągłego tekstu",
            vary_paragraphs: "Zwiększ różnorodność akapitów",
            consistent_length: "Zachowaj spójną długość akapitów",
          },
        },
        other: {
          label: "Inne Aspekty",
          choices: {
            replace_context:
              "Zastąp odniesienia do CONTEXT rzeczywistą nazwą źródła",
            add_numbering: "Dodaj numerację akapitów",
            remove_numbering: "Usuń numerację akapitów",
            extend_statutories: "Rozwiń tekst dotyczący ustaw",
            reduce_statutories: "Skróć tekst dotyczący ustaw",
            extend_jurisprudence: "Rozwiń tekst dotyczący orzecznictwa",
            reduce_jurisprudence: "Skróć tekst dotyczący orzecznictwa",
          },
        },
      },
    },
    "prompt-step": {
      title: "Wybierz Prompt",
      description: "Wybierz, jak chcesz ulepszyć odpowiedź",
    },
    actions: {
      next: "Dalej",
      back: "Wstecz",
      upgrade: "Ulepsz Odpowiedź",
      cancel: "Anuluj",
    },
  },
  "pdr-settings": {
    title: "Ustawienia PDR",
    description:
      "Kontroluj dynamiczne mechanizmy obsługi limitów tokenów dla algorytmu PDR",
    "desc-end":
      "Zmodyfikuj to ustawienie tylko wtedy, gdy rozumiesz, jak działa algorytm PDR.",
    "pdr-token-limit": "Limit Tokenów PDR",
    "pdr-token-limit-desc":
      "Maksymalna liczba tokenów, które będą używane dla algorytmu PDR.",
    "input-prompt-token-limit": "Limit Tokenów Promptu Wejściowego",
    "input-prompt-token-limit-desc":
      "Maksymalna liczba tokenów, które będą używane dla promptu wejściowego.",
    "response-token-limit": "Limit Tokenów Odpowiedzi",
    "response-token-limit-desc":
      "Maksymalna liczba tokenów, które będą używane dla odpowiedzi.",
    "toast-success": "Ustawienia PDR zaktualizowane",
    "toast-fail": "Nie udało się zaktualizować ustawień PDR",
    "adjacent-vector-limit": "Limit Sąsiadujących Wektorów",
    "adjacent-vector-limit-desc":
      "Liczba sąsiadujących wektorów, które zostaną użyte w algorytmie PDR.",
  },
  "validate-response": {
    title: "Zweryfikuj odpowiedź",
    "toast-fail": "Nie udało się zweryfikować odpowiedzi",
    validating: "Weryfikacja odpowiedzi",
  },
};

export default TRANSLATIONS;
