export default function GeminiLLMOptions({ settings, moduleSuffix = "" }) {
  return (
    <div className="w-full flex flex-col">
      <div className="w-full flex items-center gap-4">
        <div className="flex flex-col w-full">
          <label className="normal-text text-sm font-semibold block mb-4">
            Google AI API Key
          </label>
          <input
            type="password"
            name={`GeminiLLMApiKey${moduleSuffix}`}
            placeholder="Google Gemini API Key"
            defaultValue={
              settings?.[`GeminiLLMApiKey${moduleSuffix}`] ? "*".repeat(20) : ""
            }
            required={true}
            autoComplete="off"
            spellCheck={false}
            className="dark-input-mdl modal-search-block normal-text  text-sm rounded-lg block w-full p-2.5"
          />
        </div>

        {!settings?.[`credentialsOnly${moduleSuffix}`] && (
          <>
            <div className="flex flex-col w-60">
              <label className="normal-text text-sm font-semibold block mb-4">
                Chat Model Selection
              </label>
              <select
                name={`GeminiLLMModelPref${moduleSuffix}`}
                defaultValue={
                  settings?.[`GeminiLLMModelPref${moduleSuffix}`] ||
                  "gemini-pro"
                }
                required={true}
                className="dark-input-mdl normal-text text-sm rounded-lg block w-full p-2.5"
              >
                {[
                  "gemini-pro",
                  "gemini-1.0-pro",
                  "gemini-1.5-pro-latest",
                  "gemini-1.5-flash-latest",
                  "gemini-1.5-pro-002",
                  "gemini-1.5-flash-002",
                  "gemini-1.5-flash-8b-exp-0827",
                  "gemini-exp-1206",
                ].map((model) => {
                  return (
                    <option key={model} value={model} className="normal-text">
                      {model}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="flex flex-col w-60">
              <label className="normal-text text-sm font-semibold block mb-4">
                Safety Setting
              </label>
              <select
                name={`GeminiSafetySetting${moduleSuffix}`}
                defaultValue={
                  settings?.[`GeminiSafetySetting${moduleSuffix}`] ||
                  "BLOCK_MEDIUM_AND_ABOVE"
                }
                required={true}
                className="dark-input-mdl modal-search-block normal-text  text-sm rounded-lg block w-full p-2.5"
              >
                <option value="BLOCK_NONE">None</option>
                <option value="BLOCK_ONLY_HIGH">Block few</option>
                <option value="BLOCK_MEDIUM_AND_ABOVE">
                  Block some (default)
                </option>
                <option value="BLOCK_LOW_AND_ABOVE">Block most</option>
              </select>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
